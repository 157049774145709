<template>
  <v-container>
    <v-row>
      <v-col
          cols="12"
          sm="2"
        >
          <v-select
            class="text-capitalize"
            dense
            v-model="windowAggregator.M.datatype.S"
            :items="endpointEnum.datatype"
            label="Data Type *"
            :rules="[fieldRequire]"
            required
            @change="($event) => {
              windowAggregator.M.returnValue.S = $event !== 'list' ? windowAggregator.M.returnValue.S = 'value' : null
            }"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            dense
            v-model="windowAggregator.M.keyName.S"
            color="blue darken-2"
            label="Key name *"
            required
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <span>Value *</span>
          <msnl-editor :value="windowAggregator.M.value" @input="windowAggregator.M.value = $event" :minLine="10" :maxLine="10"></msnl-editor>
        </v-col>
        <v-col
          cols="12"
        >
          <div class="text-overline">End of Task</div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            class="text-capitalize"
            dense
            v-model="windowAggregator.M.endOfTask.M.by.S"
            :items="endpointEnum.endOfTask.datatypeOfBy[windowAggregator.M.datatype.S]"
            label="By *"
            @change="($event) => {
              windowAggregator.M.endOfTask.M.operation.S = $event === 'timeout' ? 'eq' : null
            }"
            required
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <span>Value *</span>
          <msnl-editor :value="windowAggregator.M.endOfTask.M.value"  @input="windowAggregator.M.endOfTask.M.value = $event"></msnl-editor>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            :disabled="windowAggregator.M.endOfTask.M.by.S === 'timeout'"
            class="text-capitalize"
            dense
            v-model="windowAggregator.M.endOfTask.M.operation.S"
            :items="endpointEnum.endOfTask.operation"
            label="Operation *"
            required
          ></v-select>
        </v-col>
        <v-col
          v-if="windowAggregator.M.datatype.S === 'hash'"
          cols="12"
          sm="6"
        >
          <v-text-field
            dense
            v-model="windowAggregator.M.endOfTask.M.hashKey.S"
            label="Hash Key"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            :disabled="['value', 'counter'].includes(windowAggregator.M.datatype.S)"
            class="text-capitalize"
            dense
            v-model="windowAggregator.M.returnValue.S"
            :items="endpointEnum.returnValue"
            label="Return Value"
            required
          ></v-select>
        </v-col>
        <v-col
          cols="12"
        >
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import endpointTemplateForm from '@/template/endpointTemplate.json'
// import merge from 'deepmerge'

export default {
  name: 'ProcessorWindowAggregatorForm',
  model: {
    prop: 'windowAggregator'
  },
  components: {
    'msnl-editor': () => import('@/components/MSNLEditor.vue')
  },
  props: {
    windowAggregator: {
      type: Object,
      default () {
        return {
        }
      },
      required: true
    }
  },
  data: () => ({}),
  methods: {
  },
  computed: {
    endpointEnum: function () {
      return endpointTemplateForm.enum.windowAggregator
    },
    fieldRequire: function () {
      return v => !!v || 'field is required'
    },
    counterRequire: function (lessLength, moreLength) {
      if (lessLength && moreLength) {
        return v => ((v || '').length >= lessLength && (v || '').length <= moreLength) || `field must be ${lessLength}-${moreLength} characters`
      } else {
        return v => (v || '').length <= lessLength || `field must be ${lessLength} characters`
      }
    }
  }
}
</script>

<style>
  .paragraph-text {
    font-size: 18px;
  }
  .centered {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
  }
</style>
